@import url('https://use.fontawesome.com/releases/v6.1.1/css/all.css');

.nav-bar {
    margin: 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.nav-bar img {
    width: 140px;
   border-radius: 100%;
   margin-left: 22px;
}

.nav-bar ul {
    display: flex;
    gap: 36px;
    margin-top: 50px;
    list-style-type: none;
    margin-left: 180px;
    font-size: 26px;
    justify-content: space-around;
}

.nav-bar ul li a {
    text-decoration: none;
    color: #000;
    font-weight: bold;
    letter-spacing: 3px;
}

.nav-bar ul li a:hover {
    background-color: #343047;
    color: #fff;
    padding: 10px 20px 10px 20px;
    font-weight: bold;
    border-radius: 32px;
}

.top-p {
    background-color: #343047;
    margin: 0px;
    padding: 6px;
    margin-bottom: 22px;
}

.top-p a {
    color: #fff;
}

.tel-icon {
    background-color: #343047;
    width: fit-content;
    height: fit-content;
    margin-top: 36px;
    margin-left: 240px;
    padding: 10px 20px 10px 20px;
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    border-radius: 32px;
}

.tel-icon i {
    margin-left: 8px;
}

@media screen and (max-width: 600px) {
    .nav-bar {
        margin: 0px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    
    .nav-bar img {
        width: 140px;
       border-radius: 100%;
       margin-left: 22px;
    }
    
    .nav-bar ul {
        display: flex;
        gap: 36px;
        margin-top: 50px;
        list-style-type: none;
        margin-left: -25px;
        font-size: 16px;
        justify-content: space-around;
    }
    
    .tel-icon {
        margin-left: 30px;
    }
}