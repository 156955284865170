.lang {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    margin: 8px;
}

.lang button {
    font-size: 16px;
    font-weight: bold;
    padding: 8px 12px;
    background-color: #343047;
    color: #fff;
    cursor: pointer;
    border-color: #343047;
    border-radius: 22px;
}

.lang button:hover {
    border: 2px solid #343047;
    color: #343047;
    background-color: transparent;
}