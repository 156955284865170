.head-sec {
    color: #333;
    font-size: 35px;
    font-weight: 700;
    text-align: left;
    width: 45%;
    margin-left: 58px;
    letter-spacing: 3px;
}

.head-sec h1 {
    margin-bottom: 0px;
    line-height: 1.0em;
}

.head-sec .head-sec-h2 {
    font-size: 20px;
    line-height: 1.3em;
}

.head-sec div {
    font-size: 18px;
    font-weight: 500;
    margin-top: 46px;
}

.head-sec div a {
    background-color: #343047;
    width: fit-content;
    height: fit-content;
    margin-top: 36px;
    margin-left: 0px;
    padding: 18px;
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    border-radius: 32px;
    letter-spacing: 2px;
    margin-right: 46px;
}

.head-sec div a:hover {
    background-color: transparent;
    color: #343047;
    border: 2px solid #343047;
}

.main-sec {
    display: flex;
    flex-wrap: wrap;
    margin-top: 90px;
    background-color: #343047;
    -webkit-transform: skewY(-10deg);
    -moz-transform: skewY(-10deg);
    -ms-transform: skewY(-1deg);
    -o-transform: skewY(-10deg);
    transform: skewY(-10deg);
    height: 100vh;
    position: relative;
}

.pic-main-sec img {
    -webkit-transform: skewY(10deg);
    -moz-transform: skewY(10deg);
    -ms-transform: skewY(1deg);
    -o-transform: skewY(10deg);
    transform: skewY(10deg);
    position: absolute;
    top: 180px;
    left: 60px;
    border-radius: 10%;
}

.cont-main-sec {
    -webkit-transform: skewY(10deg);
    -moz-transform: skewY(10deg);
    -ms-transform: skewY(1deg);
    -o-transform: skewY(10deg);
    transform: skewY(10deg);
    width: 35%;
    position: absolute;
    right: 100px;
    top: 140px;
    text-align: left;
}

.cont-main-sec h2 {
    font-size: 50px;
    font-weight: bold;
    line-height: 50px;
}

.cont-main-sec p {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.3em;
    color: #D7C16F;
}

.intro-sec {
    margin-top: 220px;
    margin-bottom: 80px;
}

.intro-sec img {
    width: 220px;
    border-radius: 22px;
    border-width: 90%;
}

.intro-sec h2 {
    font-size: 32px;
    font-weight: bold;
    color: #343047;
    margin-top: 40px;
}

.intro-sec p {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.3em;
    color: #343047;
    margin-top: 50px;
    margin-bottom: 60px;
    width: 64%;
    margin-left: 260px;
    font-weight: bold;
}

.intro-sec a {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 2px;
    color: #fff;
    text-align: center;
    background-color: #D7C16F;
    text-decoration: none;
    padding: 22px;
    border-radius: 32px;
    margin-bottom: 32px;
}

.advan {
    border-top: 1px solid black;
    width: 96%;
    margin-left: 32px;
    border-color: #DCDCDC;
}

.advan h2 {
    font-size: 50px;
    font-weight: bold;
    color: #333;
    letter-spacing: 3px;
    margin-top: 80px;
}

.advan .advan-main-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: #333;
    margin-top: 100px;
    width: 90%;
    gap: 80px;
    margin-left: 40px;
}

.advan div i {
    font-size: 80px;
    color: #333;
}

.telegram {
    margin-top: 250px;
    width: 650px;
    text-align: left;
    margin-left: 850px;
}

.telegram h2 {
    font-size: 50px;
    font-weight: 800;
    color: #343047;
    letter-spacing: 3px;
    margin-bottom: 10px;
}

.telegram span {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.3em;
    color: #D7C16F;
}

.telegram p {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.3em;
    color: #D7C16F;
    margin-top: 10px;
}

.risk-mng {
    background-color: #343047;
    background: linear-gradient(to top right, #343047 calc(50% - 1px), black, #fff calc(50% + 1px));
    height: 100vh;
    margin-top: -250px;
    margin-bottom: 0px;
}

.risk-mng img {
    position: relative;
    width: 600px;
    left: -400px;
    border-radius: 50%;
}

.risk-mng div {
    position: relative;
    bottom: -90px;
    width: 40%;
    text-align: center;
    margin-left: 32px;
}

.risk-mng div h2 {
    font-size: 50px;
    font-weight: bold;
    margin-bottom: 22px;
}

.risk-mng div p {
    font-weight: bold;
}

.risk-mng div span {
    color: #D7C16F;
    font-weight: bold;
}

.risk-mng .telegram-phone {
    position: relative;
    width: 200px;
    border-radius: 26px;
    bottom: 350px;
    left: 460px;
}

.risk-mng .telegram-phone-cont {
    position: relative;
    bottom: 374px;
    left: 265px;
    width: 190px;
    height: 385px;
    border-radius: 12px;
}

.final-sec {
    background-color: #f2f2f2;
    margin: 0px;
    text-align: center;
    height: 550px;
}

.final-sec h2 {
    margin-top: 0px;
    padding-top: 100px;
    margin-bottom: 16px;
    color: #343047;
    font-size: 42px;
    font-weight: bold;
}

.final-sec span {
    color: #D7C16F;
}

.final-sec div {
    font-weight: bold;
    color: #343047;
    margin-bottom: 80px;
}

.final-sec p {
    color: #343047;
    width: 82%;
    margin-left: 120px;
    margin-bottom: 100px;
}

.final-sec a {
    text-decoration: none;
    color: #fff;
    padding: 18px;
    background-color: #343047;
    border: 2px solid #343047;
    border-radius: 26px;
    text-align: center;
}

.final-sec a:hover {
    text-decoration: none;
    color: #343047;
    padding: 18px;
    background-color: transparent;
    border: 2px solid #343047;
    border-radius: 26px;
    text-align: center;
}

.social {
    height: 200px;
}

.social .social-div {
    color: #343047;
    display: flex;
    justify-content: space-around;
    margin-top: 80px;
}

.social .social-div div a {
    text-decoration: none;
    color: #343047;
}

.big-phone {
    position: absolute;
    width: 250px;
    top: 260px;
    right: 250px;
    z-index: 1;
    border-radius: 26px;
}

.cont-big-phone {
    position: absolute;
    width: 240px;
    height: 490px;
    top: 285px;
    right: 255px;
    z-index: 2;
    border-radius: 12px;
}

.small-phone {
    position: absolute;
    width: 200px;
    top: 390px;
    right: 110px;
    z-index: 3;
    border-radius: 26px;
}

.cont-small-phone {
    position: absolute;
    width: 190px;
    height: 391px;
    top: 413px;
    right: 115px;
    z-index: 4;
    border-radius: 12px;
}


@media screen and (max-width: 600px) {
    .head-sec {
        display: flex;
        flex-direction: column;
        font-size: 16px;
        width: 95%;
        justify-content: center;
        align-items: center;
        margin-left: 15px;
    }

    .head-sec div {
        display: flex;
        font-size: 15px;

    }

    .big-phone {
        display: none;
    }

    .cont-big-phone {
        display: none;
    }

    .small-phone {
        display: none;
    }

    .cont-small-phone {
        display: none;
    }

    .pic-main-sec {
        display: none;
    }

    .main-sec {
        width: 100%;
    }

    .cont-main-sec {
        position: relative;
        top: 0px;
        right: 0px;
        display: flex;
        flex-wrap: wrap;
        width: 90%;
        justify-content: center;
        align-items: center;
        margin-left: 25px;
    }

    .main-sec .cont-main-sec h2 {
        font-size: 26px;

    }

    .main-sec .cont-main-sec p {
        font-size: 16px;
    }

    .intro-sec p {
        margin-left: 30px;
        width: 90%;
    }

    .risk-mng {
        background-color: #343047;
        background: linear-gradient(to top right, #343047 calc(100% - 1px), black, #fff calc(100% + 1px));
        height: 100vh;
        margin-top: -250px;
        margin-bottom: 0px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .risk-mng div h2 {
        font-size: 28px;
    }

    .risk-mng div p {
        font-size: 16px;
    }

    .risk-mng div span {
        font-size: 16px;
    }

    .risk-mng div {
        width: 90%;
    }

    .risk-mng img {
        display: none;
    }

    .risk-mng .telegram-phone {
        display: none;
    }

    .risk-mng .telegram-phone-cont {
        display: none;
    }

    .advan .advan-main-div {
        margin-left: 0px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        gap: 100px;
    }

    .advan .advan-main-div i {
        font-size: 32px;
    }

    .advan .advan-main-div p {
        font-size: 16px;
    }

    .advan h2 {
        font-size: 32px;
    }

    .final-sec {
        height: 850px;
    }

    .final-sec p {
        width: 90%;
        margin-left: 22px;
    }

    .social-div i {
        font-size: 22px;
    }

    .social-div p {
        font-size: 18px;
    }
}