.log-reg {
    background-color: #F2F2F2;
}

.log-reg h2 {
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    color: #333;
    padding-top: 46px;
}

.log-reg  {
    gap: 30px;
    color: #333;
    width: 100%;
    margin-bottom: 40px;
}

.log-reg .log {
    width: 45%;
    border: 1px solid #d6d2d6;
}

.log-reg .reg {
    width: 45%;
    border: 1px solid #d6d2d6;
}

.log-reg div {
    display: flex;
    justify-content: space-around;
}