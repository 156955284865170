.main {
    background-color: #F2F2F2;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.group-h2 {
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    color: #333;
    padding: 22px;
    margin: 0px;
    margin-top: 22px;
    background-color: #343047;
    color: #fff;
}

.main .main-first {
    width: 90%;
    color: #fff;
    border: 2px solid #343047;
    margin-top: 22px;
    border-radius: 12px;
    margin-bottom: 22px;
}

.main .main-first h3 {
    background-color: #343047;
    color: #fff;
    font-weight: bold;
    margin: 0px;
    padding: 18px;
    border-radius: 10px;
}

.main .main-first p {
    text-align: center;
    color: #343047;
}

.main a {
    display: block;
    text-decoration: none;
    background-color: #343047;
    padding: 12px 36px 12px 36px;
    color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.main a:hover {
    display: block;
    text-decoration: none;
    background-color: transparent;
    padding: 12px 36px 12px 36px;
    color: #343047;
    border: 2px solid #343047;
    border-radius: 10px;
}

.main .main-second {
    width: 90%;
    color: #fff;
    border: 2px solid #343047;
    margin-top: 22px;
    border-radius: 12px;
    margin-bottom: 22px;
}

.main .main-second h3 {
    background-color: #343047;
    color: #fff;
    font-weight: bold;
    margin: 0px;
    padding: 18px;
    border-radius: 10px;
}

.main .main-second p {
    text-align: center;
    color: #343047;
}


@media screen and (max-width: 600px) {
    .main .main-first {
        width: 90%;
        
    }

    

    .main .main-second {
        width: 90%;
    }
}