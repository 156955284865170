.login-form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.login-form input {
    cursor: pointer;
    height: 26px;
    width: 90%;
    margin-bottom: 22px;
    border-radius: 8px;
}

.login-form .login-btn {
    background-color: #343047;
    color: #fff;
    font-weight: bold;
}